import React from "react"
import styled from "styled-components"

const SideTitle = props => {
  return (
    <Container>
      <div className="col">
        <h1>{props.title}</h1>
      </div>
      <div className="col">
        <p>{props.text}</p>
      </div>
    </Container>
  )
}

export default SideTitle

const bp = {
  sm: "1000px",
}

const Container = styled.div`
  margin-bottom: 40px;

  @media screen and (min-width: ${bp.sm}) {
    display: flex;
    align-items: flex-end;
  }

  h1 {
    margin-bottom: -3px;
    padding-right: 40px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  * {
    margin: 0;
  }

  .col {
    &:first-child {
      @media screen and (min-width: 500px) {
        width: 40%;
      }
    }

    &:nth-child(2) {
      padding-top: 10px;

      @media screen and (min-width: ${bp.sm}) {
        p {
          font-size: 1.3rem;
        }
      }
    }
  }
`
