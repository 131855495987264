import React from "react"
import { Grid } from "@material-ui/core"
import styled from "styled-components"

// import app components
import FormContainer from "components/form"
import Textarea from "components/textarea"

import { Socials } from "components"

const Contact = props => {
  return (
    <Container id="contact">
      <div className="grid-edges">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {props.text && <Textarea content={props.text} />}

            <Socials color="#000" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormContainer {...props} hideTitle={true} />
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding-bottom: 50px;
`

export default Contact
