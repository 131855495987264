import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"

// import app components
import { DesktopMenu, MobileMenu } from "components"
import Logo from "icons/CMA.svg"
import { useStore } from "store"
import * as theme from "theme"

const Header = props => {
  const { path } = props

  const [
    {
      appState: { menu },
    },
    dispatch,
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path, dispatch])

  return (
    <>
      <Container>
        <div className="grid-edges">
          <Grid>
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <Logo />
              </Link>
            </LogoContainer>

            <Navigation>
              <DesktopMenu
                className="desktop-menu"
                style={{ marginRight: "10px" }}
              />

              <HamburgerMenuContainer
                onClick={() => dispatch({ type: "TOGGLE_MENU" })}
              >
                <HamburgerMenu
                  color={theme.colors.dark}
                  isOpen={menu}
                  width={18}
                  height={15}
                  strokeWidth={1}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Navigation>
          </Grid>
        </div>
      </Container>

      <MobileMenu />
    </>
  )
}

export default Header

const Container = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  z-index: 999;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
`

const Grid = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .desktop-menu {
    @media (max-width: 959px) {
      display: none;
    }
  }
`

const LogoContainer = styled.div`
  margin: 20px 0;
  a {
    text-decoration: none;
  }
  margin-left: 10px;
  svg {
    width: 180px;
    @media (min-width: 450px) {
      margin-left: 0;
      width: 230px;
    }
  }
`

const HamburgerMenuContainer = styled.div`
  padding: 15px;
  cursor: pointer;

  @media (min-width: 960px) {
    display: none;
  }
`
