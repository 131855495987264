// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-post-types-page-default-js": () => import("./../../../src/templates/postTypes/page/default.js" /* webpackChunkName: "component---src-templates-post-types-page-default-js" */),
  "component---src-templates-post-types-post-archive-js": () => import("./../../../src/templates/postTypes/post/archive.js" /* webpackChunkName: "component---src-templates-post-types-post-archive-js" */),
  "component---src-templates-post-types-post-default-js": () => import("./../../../src/templates/postTypes/post/default.js" /* webpackChunkName: "component---src-templates-post-types-post-default-js" */)
}

