import React from "react"
import styled from "styled-components"
import ListIcon from "icons/check-outline.svg"
import { colors } from "theme"
import GatsbyImage from "gatsby-image"
import BackgroundImage from "./backgroundImage"
import Parse from "html-react-parser"
import Button from "./button"
import { Link } from "gatsby"
import Arrow from "../icons/arrow-down.svg"

const DownloadCard = props => {
  return (
    <CardWrapper width={props.width}>
      <ImageBox>
        <ImageContainer>
          <BackgroundImage
            image={props.image}
            alt={props.image.altText}
            className="object-cover w-full h-56 rounded shadow-lg"
          />
        </ImageContainer>

        {props.title && <TitleArea>{props.title}</TitleArea>}
        <TextArea>{props.text && Parse(props.text)}</TextArea>
        {props.link && (
          <ButtonContainer>
            <Link
              className={`button button--icon-${
                props.color ? props.color : "yellow"
              } card__button`}
              href={props.link}
              style={{ marginRight: 18 }}
              target="_blank"
              rel="noopener referrer"
              download
            >
              Download
              <span className="button__icon">
                <Arrow className={"submitArrow"} />
              </span>
            </Link>
          </ButtonContainer>
        )}
      </ImageBox>
    </CardWrapper>
  )
}

export default DownloadCard

const ImageBox = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  z-index: 10;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`

const ImageContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  .imageGatsby {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 30px !important;
`

const TitleArea = styled.h4`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px !important;
  max-width: 300px;
`
const TextArea = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  max-width: 300px;

  p {
    margin-bottom: 15px !important;
  }
`

const CardWrapper = styled.article`
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 1px;

  li {
    display: flex;
    letter-spacing: 0.25px;
    padding-right: 30px;
  }

  ul {
    padding-top: 20px;
  }

  svg {
    margin-top: 6px;
    margin-right: 10px;
    min-width: 15px !important;
    width: 15px !important;
    height: 15px !important;
  }

  path {
    fill: ${colors.dark};
  }

  @media screen and (min-width: 900px) {
    width: ${({ width }) =>
      width === "half"
        ? `calc(50% - 20px)`
        : width === "third"
        ? `calc(33.3% - 20px)`
        : "100%"};
  }
`
