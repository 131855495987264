import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Parse from "html-react-parser"

import { Button } from "components"

import { colors } from "theme"

const LandingServices = props => {
  const { badgeImage, text, button, services } = props

  return (
    <Container className="grid-edges content">
      <Text>
        {text && Parse(text)}

        {button && (
          <Button
            to={button.url}
            color="yellow"
            children={button.title}
            gatsbyButton
          />
        )}

        {badgeImage && (
          <Badge>
            <Img fluid={badgeImage?.localFile?.childImageSharp?.fluid} />
          </Badge>
        )}
      </Text>

      <Services>
        {services &&
          services.map((service, index) => {
            const { headline, text } = service
            return (
              <Item key={index}>
                <h3>{headline}</h3>
                <div>{text && Parse(text)}</div>
              </Item>
            )
          })}
      </Services>
    </Container>
  )
}

export default LandingServices

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 40px auto;
  @media (min-width: 768px) {
    margin: 80px auto;
  }
`

const Text = styled.div`
  width: 25%;
  min-width: 300px;
  margin-right: 100px;
  margin-bottom: 30px;
  .button {
    margin-left: auto;
    margin-right: auto;
    background: ${colors.orange};
    color: black;
    font-weight: 500;
  }
`

const Badge = styled.div`
  margin: 40px 0;
  max-width: 220px;
`

const Services = styled.div``

const Item = styled.div`
  margin-bottom: 40px;
  > h3 {
    color: #ffb443;
    font-weight: bold;
  }
  > h4 {
    margin-bottom: 10px;
  }
`
