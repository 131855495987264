import React from "react"
import styled from "styled-components"

import Textarea from "components/textarea"

const bp = {
  sm: "500px",
  md: "900px",
}

const TestimonialStyles = styled.div`
  .testimonial {
    margin-bottom: 80px;
    margin-left: 30px;
    max-width: 1000px;
    @media screen and (min-width: ${bp.sm}) {
      margin-left: 50px;
    }
  }

  .quote {
    font-size: 19px;
    @media screen and (min-width: ${bp.sm}) {
      font-size: 22px;
    }
    @media screen and (min-width: ${bp.md}) {
      font-size: 25px;
    }
    font-style: italic;
    font-weight: 100;
  }

  .title {
    font-size: 20px;
    letter-spacing: 0.5px;
    margin-top: 30px;
  }

  .opening-quote {
    font-size: 50px;
    position: absolute;
    height: 25px;
    line-height: 25px;
    top: 14px;
    left: -30px;

    @media screen and (min-width: ${bp.sm}) {
      font-size: 100px;
      top: 28px;
      left: -50px;
    }
  }

  .testimonial-wrapper {
    position: relative;
  }
`

const TestimonialText = props => {
  return (
    <div className="testimonial-wrapper">
      <span className="opening-quote">&ldquo;</span>
      <span className="quote">
        <Textarea content={props.text} />
      </span>
    </div>
  )
}

const Testimonial = props => {
  return (
    <TestimonialStyles>
      <div className="testimonial">
        {props.item.text && <TestimonialText text={props.item.text} />}
      </div>
    </TestimonialStyles>
  )
}

const TestimonialsStyles = styled.div`
  padding-top: 40px;
  padding-bottom: 1px;

  h1 {
    margin-bottom: 50px;
  }
`

const Testimonials = props => {
  return (
    <TestimonialsStyles>
      <div className="grid-edges">
        <h1>{props.headline}</h1>
        {props.items.map((item, i) => {
          return <Testimonial item={item} key={i} />
        })}
      </div>
    </TestimonialsStyles>
  )
}

export default Testimonials
