import React from "react"

// import flexible content modules
import Text from "./Text"
import Acuity from "./Acuity"
import Downloads from "./Downloads"
import Hero from "./Hero"
import Services from "./Services"
import Banner from "./Banner"
import Contact from "./Contact"
import Map from "./Map"
import Testimonials from "./Testimonials"
import Badges from "./Badges"
import TestimonialBanner from "./TestimonialBanner"
import LandingServices from "./LandingServices"

const FlexibleContent = props => {
  const { rows, data } = props

  if (!!rows) {
    return rows
      .filter(o => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        if (!__typename) {
          return null
        }

        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Hero,
          Text,
          Services,
          Banner,
          Contact,
          Map,
          Testimonials,
          Badges,
          TestimonialBanner,
          LandingServices,
          Acuity,
          Downloads,
        }
        const Component = components[type]
        return Component && <Component key={index} {...rowData} {...data} />
      })
  }
}

export default FlexibleContent
