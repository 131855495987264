import React from "react"
import styled, { css } from "styled-components"
import Parse from "html-react-parser"

import BackgroundImage from "components/backgroundImage"

import YellowQuote from "icons/cma-yellow-quote.svg"

const TestimonialBanner = props => {
  const { backgroundImage: image, text } = props

  return (
    <Container>
      <section className="o-intro-box grid-edges--border">
        <div className="grid-edges content">
          <div className="text">
            <div
              className="p"
              css={css`
                h2 {
                  font-family: georgia;
                }
              `}
            >
              {text && Parse(text)}
            </div>
          </div>
        </div>

        <ParallaxBg className={`bg parallax-bg--top`}>
          <YellowQuote />
          {!!image && (
            <BackgroundImage
              overlay
              className={`inner`}
              image={image}
              verticalAlignment="0%"
            />
          )}
        </ParallaxBg>
      </section>
    </Container>
  )
}

export default TestimonialBanner

const Container = styled.div`
  .gatsby-image-wrapper {
    position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .o-intro-box {
    background-color: #ffb443;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    border-bottom-right-radius: 160px;
    border-top-left-radius: 20px;
    position: relative;
    margin-bottom: 70px !important;
    box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.11);
    padding: 120px 0 70px;
  }
  .content {
    position: relative;
    z-index: 3;
  }
  .logo {
    margin-top: 100px;
    margin-bottom: auto;
  }
  .heading {
    margin-bottom: 10px;
  }
  .text {
    margin-bottom: 50px;
    @media screen and (min-width: 1001px) and (max-width: 1200px) {
      max-width: 54%;
    }
  }
  .p {
    max-width: 600px;
  }
  .bg {
    background: #f1f4f8;
    position: absolute !important;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 100%;
    border-bottom-right-radius: 100px;
  }

  @media screen and (max-width: 700px) {
    .bg {
      border-bottom-right-radius: 80px;
    }
  }
  @media screen and (max-width: 1000px) {
    .bg:before {
      background: rgba(237, 237, 238, 0.8);
    }
  }
  .triangle {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 500px;
    max-width: none;
    height: 100%;
  }
  .triangle-shape {
    height: 100%;
    width: 100%;
    background: #ffb443;
    transform-origin: bottom right;
    transform: translateX(-100%) rotate(20deg) scale(1.1);
  }
`

const ParallaxBg = styled.div`
  position: relative;
  overflow: hidden;
  & > * {
    position: relative;
    z-index: 2;
  }
  .inner {
    position: absolute !important;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-size: cover;
    background-position: center;
    z-index: 1;
    @media screen and (max-width: 1000px) {
      &.hide-mobile-image {
        display: none;
      }
    }
  }

  .background-position-top {
    img {
      object-position: top center !important;
    }
  }
  display: flex;
  align-items: center;
  > svg {
    path {
      fill: #ffb443;
    }
  }
`
