import React from "react"
import Cards from "../Cards"
import Card from "../Card"
import SideTitle from "../SideTitle"

const Services = props => {
  const { headline, introduction, boxes } = props

  return (
    <div id="services" className="grid-edges">
      <SideTitle title={headline} text={introduction} />

      <Cards>
        {boxes &&
          boxes.map((o, i) => {
            return (
              <Card
                key={i}
                width={o.width}
                title={o.headline}
                listItems={o.items}
              />
            )
          })}
      </Cards>
    </div>
  )
}

export default Services
