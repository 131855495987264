import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
// import MenuItems from "./MenuItems"

import { Typography } from "@material-ui/core"

// import app components
import useMenuItems from "./useMenuItems"
import { getUrlPath } from "utils"

const FooterMenu = props => {
  const items = useMenuItems("desktop-main-menu")

  return (
    <Menu {...props}>
      {items &&
        items.map(({ id, url, label }) => (
          <MenuItem key={id}>
            <MenuLink to={getUrlPath(url)}>
              <Typography
                color="inherit"
                component="div"
                variant="body2"
                children={Parser(label)}
              />
            </MenuLink>
          </MenuItem>
        ))}
    </Menu>
  )
}

export default FooterMenu

const Menu = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
  }
`

const MenuLink = styled(Link)`
  padding: 20px 10px;
  margin-left: 20px;
  color: #fff;
`
