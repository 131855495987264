import React from "react"
import styled from "styled-components"
import { colors } from "theme"

import Button from "components/button"
import BackgroundImage from "components/backgroundImage"

const Banner = props => {
  return (
    <Container>
      {props.image && <BackgroundImage image={props.image} />}
      <Inner>
        {props.headline && <h1 children={props.headline} />}
        {props.button && (
          <Button
            to={props.button.url}
            children={props.button.title}
            gatsbyButton
          />
        )}
      </Inner>
    </Container>
  )
}

export default Banner

const Container = styled.section`
  width: 100%;
  min-height: 60vh;
  background: ${colors.dark};
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  margin-bottom: 50px;

  .button {
    margin-left: auto;
    margin-right: auto;
    background: ${colors.orange};
    color: black;
    font-weight: 500;
  }

  position: relative;

  .gatsby-image-wrapper {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }
`

const Inner = styled.section`
  position: relative;
  z-index: 1;
`
