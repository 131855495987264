import React from "react"
import ReactMapboxGl, { Marker } from "react-mapbox-gl"
import "./Map.css"
import mapboxgl from "mapbox-gl"
import { css } from "styled-components"
import MarkerIcon from "icons/marker.svg"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

// Run Map only on the frontend, not on the server build
// The loader is nullified for html builds in gatsby-node.js as well.
let Map = false

if (typeof window !== `undefined`) {
  Map = ReactMapboxGl({
    accessToken:
      "pk.eyJ1IjoiYmFyZWFkdmVydGlzaW5nIiwiYSI6ImNqaWRmdDMxZDAxdWw0MW9jYXRjYWRqZ3QifQ.DPR-gLvJOZzyf4X-Yk3V0g",
    scrollZoom: false,
    refreshExpiredTiles: false,
    inject: false,
  })
}

const markerStyles = css`
  cursor: pointer;
`

function openGoogleMap() {
  window.open(
    "https://www.google.ca/maps/place/Cynthia+Medrano+%26+Associates/@49.3098287,-123.0807272,16z/data=!4m5!3m4!1s0x0:0x3be4bcb385770962!8m2!3d49.32875311507788!4d-123.13309390187645",
    "_blank"
  )
}

const Mapbox = () => {
  return Map ? (
    <Map
      // eslint-disable-next-line
      style="mapbox://styles/bareadvertising/cjn3xkdyw1d4x2sp87hrpr3qe"
      containerStyle={{
        height: "100vh",
        width: "100vw",
      }}
      zoom={[12]}
      center={[-123.13309390187645, 49.32875311507788]}
    >
      <Marker
        coordinates={[-123.13309390187645, 49.32875311507788]}
        onClick={openGoogleMap}
        anchor="bottom"
      >
        <MarkerIcon className={markerStyles} />
      </Marker>
    </Map>
  ) : null
}

export default Mapbox
