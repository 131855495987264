import React from "react"
import styled, { css } from "styled-components"
import { Grid } from "@material-ui/core"

import { Socials } from "components"
// import app components
import FooterMenu from "../menu/FooterMenu"

const Footer = () => {
  return (
    <FooterContainer position="static" color="primary">
      <div className="grid-edges">
        <Grid container justify="space-between" alignItems="center">
          <div>
            <span
              css={css`
                color: #fff;
                display: block;
              `}
            >
              &copy; {new Date().getFullYear()} CM&A Bookkeeping and Tax
              Services Inc.
            </span>
          </div>

          <Socials color="#fff" />
          <div>
            <FooterMenu />
            <HyperLink
              href="https://jambaree.com/"
              target="_blank"
              css={css`
                text-align: end;
                font-size: 14px;
              `}
            >
              Gatsby site development by Jambaree
            </HyperLink>
          </div>
        </Grid>
      </div>
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer`
  padding: 20px 0;
  background: #000;
`

const HyperLink = styled.a`
  color: #fff;
  text-decoration: none;
  display: block;
`
