import React from "react"
import styled from "styled-components"

const Cards = props => {
  return <Container>{props.children}</Container>
}

export default Cards

const Container = styled.div`
  @media screen and (min-width: 900px) {
    display: flex;
    flex-wrap: wrap;
  }

  margin: -10px;
  margin-bottom: 50px;

  > article {
    margin: 10px;
  }

  p {
    margin: 0;
  }
`
