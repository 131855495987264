import React from "react"
import Arrow from "icons/arrow-down.svg"
import { css } from "styled-components"
import { Link } from "gatsby"

const Button = props => {
  return props.gatsbyButton ? (
    <Link
      to={props.to}
      className={`button button--icon-${
        props.color ? props.color : "yellow"
      } card__button`}
    >
      {props.children}
      <span className="button__icon">
        <Arrow
          className={props.arrowDirection === "right" ? submitArrow : ""}
        />
      </span>
    </Link>
  ) : props.type === "submit" ? (
    <button
      type="submit"
      className={`button button--icon-${
        props.color ? props.color : "yellow"
      } card__button`}
    >
      {props.children}
      <span className="button__icon">
        <Arrow
          className={props.arrowDirection === "right" ? submitArrow : ""}
        />
      </span>
    </button>
  ) : (
    <a
      href={props.to}
      className={`button button--icon-${
        props.color ? props.color : "yellow"
      } card__button`}
    >
      {props.children}
      <span className="button__icon">
        <Arrow
          className={props.arrowDirection === "right" ? submitArrow : ""}
        />
      </span>
    </a>
  )
}

export default Button

const submitArrow = css`
  transform: rotate(-90deg);
`
