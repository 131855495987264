import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

// import app components
import { Textarea } from "components"
import { Link } from "gatsby"
import Arrow from "../../icons/arrow-down.svg"

const Acuity = props => {
  const { text } = props

  const [taxFormSelected, setTaxFormSelected] = useState(false)

  useEffect(() => {
    typeof window !== `undefined` && window.scrollTo(0, 0)
  }, [])

  return (
    <Container className="grid-edges">
      {/*
    
      <ButtonContainer>
        <ButtonBox
          className={`button button--icon-${
            props.color ? props.color : "yellow"
          } card__button`}
          style={{ marginRight: 18 }}
          onClick={() => setTaxFormSelected(false)}
        >
          General Consultation
          <span className="button__icon">
            <Arrow className={"submitArrow"} />
          </span>
        </ButtonBox>
        <ButtonBox
          className={`button button--icon-${
            props.color ? props.color : "yellow"
          } card__button`}
          style={{ marginRight: 18 }}
          onClick={() => setTaxFormSelected(true)}
        >
          Tax Appointment
          <span className="button__icon">
            <Arrow className={"submitArrow"} />
          </span>
        </ButtonBox>
      </ButtonContainer>
       */}
      <iframe
        src={text}
        title="Schedule Appointment"
        scrolling="no"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
      <script
        src="https://embed.acuityscheduling.com/js/embed.js"
        type="text/javascript"
      ></script>
    </Container>
  )
}

export default Acuity

const Container = styled.div`
  height: 6000px;
  width: 100%;
  @media (max-width: 680px) {
    height: 6500px;
  }
  @media (max-width: 500px) {
    height: 7000px;
  }
  @media (max-width: 400px) {
    height: 8000px;
  }
  @media (max-width: 320px) {
    height: 9000px;
  }
`
const ButtonContainer = styled.div`
  margin-bottom: 30px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`
const ButtonBox = styled.div`
  margin-right: 50px !important;
  @media (max-width: 850px) {
    margin-bottom: 30px;
    margin-right: 0px !important;
    font-size: 13px !important;
    letter-spacing: 1px !important;
  }
`
