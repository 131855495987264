import React from "react"
import styled from "styled-components"
import ListIcon from "icons/check-outline.svg"
import { colors } from "theme"

const Card = props => {
  return (
    <CardWrapper width={props.width}>
      {props.title && <TitleArea>{props.title}</TitleArea>}
      {props.listItems && (
        <ul>
          {props.listItems.map((item, i) => (
            <li key={i}>
              <ListIcon /> {item.text}
            </li>
          ))}
        </ul>
      )}
    </CardWrapper>
  )
}

export default Card

const TitleArea = styled.h5`
  background: ${colors.yellow};
  color: ${colors.dark};
  font-weight: 400;
  padding: 10px 20px 12.5px 20px;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 0.7rem;
  margin-bottom: 0;
  display: inline-block;
  width: auto;
  border-radius: 0 0 15px 0;
`

const CardWrapper = styled.article`
  background: ${colors.grey2};
  padding-bottom: 1px;

  li {
    display: flex;
    letter-spacing: 0.25px;
    padding-right: 30px;
  }

  ul {
    padding-top: 20px;
  }

  svg {
    margin-top: 6px;
    margin-right: 10px;
    min-width: 15px !important;
    width: 15px !important;
    height: 15px !important;
  }

  path {
    fill: ${colors.dark};
  }

  @media screen and (min-width: 900px) {
    width: ${({ width }) => (width === "half" ? `calc(50% - 20px)` : "100%")};
  }
`
