import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// import app components
import { Textarea } from "components"
import Cards from "../Cards"
import DownloadCard from "../DownloadCard"
import SideTitle from "../SideTitle"

const Downloads = props => {
  const { downloads, width, headline, introduction } = props

  return (
    <div id="downloads" className="grid-edges">
      <SideTitle title={headline} text={introduction} />

      <Cards>
        {downloads &&
          downloads
            .filter(o => !!o.file?.localFile?.publicURL)
            .map((o, i) => {
              return (
                <DownloadCard
                  key={i}
                  width={width}
                  title={o.file.title}
                  text={o.file.description}
                  link={o.file.localFile.publicURL}
                  image={o.logo}
                />
              )
            })}
      </Cards>
    </div>
  )
}

export default Downloads

const Container = styled.div`
  height: 100%;
  width: 100%;
`
