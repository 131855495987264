const colors = {
  dark: "#263f53",
  orange: "rgba(255, 180, 67, 1)",
  yellow: "rgba(255, 208, 123, 1)",
  almond: "rgba(238, 224, 203, 1)",
  offwhite: "rgba(249, 251, 242, 1)",
  grey1: "#e9edf3",
  grey2: "#f1f4f8",
  primary: "#00A497",
  secondary: "#e24141",
  background: {
    light: "#fff",
    dark: "#E5EFEF",
  },
  text: {
    light: "#405C5E",
    dark: "#232323",
  },
  success: "#00A497",
  error: "#e24141",
}

export default colors
