import React from "react"

// import app components
import { Textarea } from "components"

const Text = props => {
  const { text } = props

  return (
    <div className="grid-edges">
      <Textarea content={text} />
    </div>
  )
}

export default Text
