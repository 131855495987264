import { createGlobalStyle } from "styled-components"
import typographyString from "./typography"
import colors from "./colors"

export default createGlobalStyle`
  ${typographyString}
  @font-face {
    font-family: "EksellDisplay";
    src: url("../fonts/EksellDisplay/EksellDisplay-Medium.woff") format("woff");
    font-weight: medium;
    font-style: normal;
  }

html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  max-width: 100%;
}

img {
  height: auto;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

h1 {
  font-size: 2em;
  padding: 0.67em 0;
  margin: 0;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  padding: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  -moz-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.menu ul,
.menu {
  list-style: none;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  clip: auto !important;
  display: block;
  height: auto;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

html {
  font: 112.5%/1.5 Trueno, helvetica;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
}

* {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

*:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Trueno", "helvetica";
  font-weight: 300;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
}

img {
  max-width: 100%;
  margin: 0 0 1.5rem;
  padding: 0;
}

h1 {
  margin: 0 0 1.5rem;
  padding: 0;
  color: inherit;
  font: 400 2rem/1.1 georgia;
  text-rendering: optimizeLegibility;
}

h2 {
  margin: 0 0 1.5rem;
  padding: 0;
  color: inherit;
  font: 400 1.51572rem/1.1 georgia;
  text-rendering: optimizeLegibility;
}

h3 {
  margin: 0 0 1.5rem;
  padding: 0;
  color: inherit;
  font: 400 1.31951rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
  text-rendering: optimizeLegibility;
}

h4 {
  margin: 0 0 1.5rem;
  padding: 0;
  color: inherit;
  font: 400 1rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
  text-rendering: optimizeLegibility;
}

h5 {
  margin: 0 0 1.5rem;
  padding: 0;
  color: inherit;
  font: 400 0.87055rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
  text-rendering: optimizeLegibility;
}

h6 {
  margin: 0 0 1.5rem;
  padding: 0;
  color: inherit;
  font: 400 0.81225rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
  text-rendering: optimizeLegibility;
}

hgroup {
  margin: 0 0 1.5rem;
  padding: 0;
}

ul {
  margin: 0 0 1.5rem 1.5rem;
  padding: 0;
  list-style: outside none;
}

ol {
  margin: 0 0 1.5rem 1.5rem;
  padding: 0;
  list-style: outside none;
}

dl {
  margin: 0 0 1.5rem;
  padding: 0;
}

dd {
  margin: 0 0 1.5rem;
  padding: 0;
}

p {
  margin: 0 0 1.5rem;
  padding: 0;
}

figure {
  margin: 0 0 1.5rem;
  padding: 0;
}

pre {
  margin: 0 0 1.5rem;
  padding: 0;
  font-size: 0.85rem;
  line-height: 1.5rem;
}

table {
  margin: 0 0 1.5rem;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  border-collapse: collapse;
  width: 100%;
}

fieldset {
  margin: 0 0 1.5rem;
  padding: 0;
}

blockquote {
  margin: 0 1.5rem 1.5rem;
  padding: 0;
}

form {
  margin: 0 0 1.5rem;
  padding: 0;
}

noscript {
  margin: 0 0 1.5rem;
  padding: 0;
}

iframe {
  margin: 0 0 1.5rem;
  padding: 0;
}

hr {
  margin: 0 0 calc(1.5rem - 1px);
  padding: 0;
  background: rgba(0, 0, 0, 0.2);
  border: 0;
  height: 1px;
}

address {
  margin: 0 0 1.5rem;
  padding: 0;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

dt {
  font-weight: bold;
}

th {
  font-weight: bold;
}

li {
  margin-bottom: calc(1.5rem / 2);
}

ol li {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}

li > ol {
  margin-left: 1.5rem;
  margin-bottom: calc(1.5rem / 2);
  margin-top: calc(1.5rem / 2);
}

li > ul {
  margin-left: 1.5rem;
  margin-bottom: calc(1.5rem / 2);
  margin-top: calc(1.5rem / 2);
}

blockquote *:last-child {
  margin-bottom: 0;
}

li *:last-child {
  margin-bottom: 0;
}

p *:last-child {
  margin-bottom: 0;
}

li > p {
  margin-bottom: calc(1.5rem / 2);
}

code {
  font-size: 0.85rem;
  line-height: 1.5rem;
}

kbd {
  font-size: 0.85rem;
  line-height: 1.5rem;
}

samp {
  font-size: 0.85rem;
  line-height: 1.5rem;
}

abbr {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help;
}

acronym {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help;
}

abbr[title] {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help;
  text-decoration: none;
}

thead {
  text-align: left;
}

td,
th {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
  padding: 0.75rem 1rem calc(0.75rem - 1px);
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

@media screen and (min-width: 800px) {
  html {
    font: 112.5%/1.5 Trueno, helvetica;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  *:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  body {
    color: rgba(0, 0, 0, 0.8);
    font-family: "Trueno", "helvetica";
    font-weight: 300;
    word-wrap: break-word;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  img {
    max-width: 100%;
    margin: 0 0 1.5rem;
    padding: 0;
  }
  h1 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 2.5rem/1.1 georgia;
    text-rendering: optimizeLegibility;
  }
  h2 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 1.73286rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h3 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 1.4427rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h4 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 1rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h5 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 0.83255rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h6 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 0.75966rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  hgroup {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  ul {
    margin: 0 0 1.5rem 1.5rem;
    padding: 0;
    list-style: outside none;
  }
  ol {
    margin: 0 0 1.5rem 1.5rem;
    padding: 0;
    list-style: outside none;
  }
  dl {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  dd {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  p {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  figure {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  pre {
    margin: 0 0 1.5rem;
    padding: 0;
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  table {
    margin: 0 0 1.5rem;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    border-collapse: collapse;
    width: 100%;
  }
  fieldset {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  blockquote {
    margin: 0 1.5rem 1.5rem;
    padding: 0;
  }
  form {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  noscript {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  iframe {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  hr {
    margin: 0 0 calc(1.5rem - 1px);
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    border: 0;
    height: 1px;
  }
  address {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  b {
    font-weight: bold;
  }
  strong {
    font-weight: bold;
  }
  dt {
    font-weight: bold;
  }
  th {
    font-weight: bold;
  }
  li {
    margin-bottom: calc(1.5rem / 2);
  }
  ol li {
    padding-left: 0;
  }
  ul li {
    padding-left: 0;
  }
  li > ol {
    margin-left: 1.5rem;
    margin-bottom: calc(1.5rem / 2);
    margin-top: calc(1.5rem / 2);
  }
  li > ul {
    margin-left: 1.5rem;
    margin-bottom: calc(1.5rem / 2);
    margin-top: calc(1.5rem / 2);
  }
  blockquote *:last-child {
    margin-bottom: 0;
  }
  li *:last-child {
    margin-bottom: 0;
  }
  p *:last-child {
    margin-bottom: 0;
  }
  li > p {
    margin-bottom: calc(1.5rem / 2);
  }
  code {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  kbd {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  samp {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  abbr {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: help;
  }
  acronym {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: help;
  }
  abbr[title] {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: help;
    text-decoration: none;
  }
  thead {
    text-align: left;
  }
  td,
  th {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding: 0.75rem 1rem calc(0.75rem - 1px);
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font: 112.5%/1.5 Trueno, helvetica;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  *:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  body {
    color: rgba(0, 0, 0, 0.8);
    font-family: "Trueno", "helvetica";
    font-weight: 300;
    word-wrap: break-word;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  img {
    max-width: 100%;
    margin: 0 0 1.5rem;
    padding: 0;
  }
  h1 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 2.8rem/1.1 "georgia";
    text-rendering: optimizeLegibility;
  }
  h2 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 1.85479rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h3 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 1.5096rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h4 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 1rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h5 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 0.8139rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  h6 {
    margin: 0 0 1.5rem;
    padding: 0;
    color: inherit;
    font: 400 0.73426rem/1.1 "Trueno",Arial,Helvetica,sans-serif;
    text-rendering: optimizeLegibility;
  }
  hgroup {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  ul {
    margin: 0 0 1.5rem 1.5rem;
    padding: 0;
    list-style: outside none;
  }
  ol {
    margin: 0 0 1.5rem 1.5rem;
    padding: 0;
    list-style: outside none;
  }
  dl {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  dd {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  p {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  figure {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  pre {
    margin: 0 0 1.5rem;
    padding: 0;
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  table {
    margin: 0 0 1.5rem;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    border-collapse: collapse;
    width: 100%;
  }
  fieldset {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  blockquote {
    margin: 0 1.5rem 1.5rem;
    padding: 0;
  }
  form {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  noscript {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  iframe {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  hr {
    margin: 0 0 calc(1.5rem - 1px);
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    border: 0;
    height: 1px;
  }
  address {
    margin: 0 0 1.5rem;
    padding: 0;
  }
  b {
    font-weight: bold;
  }
  strong {
    font-weight: bold;
  }
  dt {
    font-weight: bold;
  }
  th {
    font-weight: bold;
  }
  li {
    margin-bottom: calc(1.5rem / 2);
  }
  ol li {
    padding-left: 0;
  }
  ul li {
    padding-left: 0;
  }
  li > ol {
    margin-left: 1.5rem;
    margin-bottom: calc(1.5rem / 2);
    margin-top: calc(1.5rem / 2);
  }
  li > ul {
    margin-left: 1.5rem;
    margin-bottom: calc(1.5rem / 2);
    margin-top: calc(1.5rem / 2);
  }
  blockquote *:last-child {
    margin-bottom: 0;
  }
  li *:last-child {
    margin-bottom: 0;
  }
  p *:last-child {
    margin-bottom: 0;
  }
  li > p {
    margin-bottom: calc(1.5rem / 2);
  }
  code {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  kbd {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  samp {
    font-size: 0.85rem;
    line-height: 1.5rem;
  }
  abbr {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: help;
  }
  acronym {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: help;
  }
  abbr[title] {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
    cursor: help;
    text-decoration: none;
  }
  thead {
    text-align: left;
  }
  td,
  th {
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding: 0.75rem 1rem calc(0.75rem - 1px);
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
  }
}

body {
  color: #0b1015;
}

.sideways-h1 {
  font-family: "Trueno", "helvetica";
  font-size: 160px;
  opacity: 0.075;
  margin-bottom: -19px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
  margin-top: -190px;
  position: relative;
  top: 10px;
  left: 20px;
}

.grid-edges {
  margin: 0 auto;

  &:not(.grid-edges--border) {
  width: 90%;
  /* padding: 0 20px; */
  @media screen and (min-width: 700px) {

    width: 88%;
    padding: 0;
  }
  }

}

/* .o-intro-box .grid-edges {
  @media screen and (max-width: 699px) {
    width: 90%;
  }
} */

@media screen and (min-width: 700px) {
  .o-intro-box {
  .grid-edges:not(.grid-edges--border) {
  transform: translateX(-10px);
}
}
}
@media (min-width: 1640px) {
  .grid-edges {
    width: 83%;
    max-width: 1440px;
  }
}
.grid-edges > .grid-edges {
  width: 100%;
}

.grid-edges--medium {
  width: 92%;
  margin: 0 auto;
  max-width: 1200px;
}
.grid-edges--medium > .grid-edges {
  width: 100%;
}

.grid-edges--small {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
}

.grid-edges--border {
  width: 95%;
  width: calc(100vw - 40px) !important;
  margin: 0 auto;
}

.bg:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.bg-overlay:before {
  
  /* background: black; */
  background: linear-gradient(to bottom,#012361 0%,transparent 20%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#000000", GradientType=0 );
  /* IE6-9 */
}

.bg-align--top {
  background-position: top center;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner::before,
.hamburger--3dx .hamburger-inner::after {
  -webkit-transition: -webkit-transform 0s 0.1s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.hamburger--3dx.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner::before,
.hamburger--3dx-r .hamburger-inner::after {
  -webkit-transition: -webkit-transform 0s 0.1s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner::before,
.hamburger--3dy .hamburger-inner::after {
  -webkit-transition: -webkit-transform 0s 0.1s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.hamburger--3dy.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner::before,
.hamburger--3dy-r .hamburger-inner::after {
  -webkit-transition: -webkit-transform 0s 0.1s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dxy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy .hamburger-inner::before,
.hamburger--3dxy .hamburger-inner::after {
  -webkit-transition: -webkit-transform 0s 0.1s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
  transform: rotateX(180deg) rotateY(180deg);
}

.hamburger--3dxy.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dxy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s
      cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r .hamburger-inner::before,
.hamburger--3dxy-r .hamburger-inner::after {
  -webkit-transition: -webkit-transform 0s 0.1s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease,
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease,
    transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
    -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease,
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease,
    transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22),
    -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.hamburger--arrowturn.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

.hamburger--arrowturn.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
   * Boring
   */
.hamburger--boring .hamburger-inner,
.hamburger--boring .hamburger-inner::before,
.hamburger--boring .hamburger-inner::after {
  -webkit-transition-property: none;
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
  transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
  transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
  transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
  transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
  transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner::before {
  -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
  transform: translate3d(0, -10px, 0) rotate(45deg);
  -webkit-transition-delay: 0.22s;
  transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0.1s 0.22s linear;
}

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
  transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  -webkit-transition: opacity 0.125s 0.275s ease;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  -webkit-transition: -webkit-transform 0.275s
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
  transform: translate3d(0, 10px, 0) rotate(135deg);
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
  transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  -webkit-transition: opacity 0.125s 0.275s ease;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  -webkit-transition: -webkit-transform 0.275s
    cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

.hamburger--elastic-r.is-active .hamburger-inner::before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg);
  -webkit-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color 0.125s 0.175s ease-in;
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  -webkit-transition: background-color 0.125s 0.175s ease-in;
  transition: background-color 0.125s 0.175s ease-in;
}

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, left 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
    top 0.05s 0.125s linear, right 0.125s 0.175s ease-in,
    -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear,
    transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before,
.hamburger--minus .hamburger-inner::after {
  -webkit-transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out,
    opacity 0s linear;
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}

.hamburger--minus.is-active .hamburger-inner::before,
.hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  -webkit-transition: bottom 0.08s ease-out, top 0.08s ease-out,
    opacity 0s 0.08s linear;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.15s;
  transition-duration: 0.15s;
}

.hamburger--slider-r .hamburger-inner::after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner::before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
  transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  -webkit-transition-duration: 0.22s;
  transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.hamburger--spin-r .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.25s ease-in,
    transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease-out,
    transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s 0.13s linear;
  transition: background-color 0s 0.13s linear;
}

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: 0.22s;
  transition-delay: 0.22s;
  background-color: transparent;
}

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
  transition-duration: 0.13s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0s linear;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
  transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0s 0.22s linear;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    opacity 0s 0.22s linear;
}

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    background-color 0s 0.075s linear,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::before {
  -webkit-transition: top 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  -webkit-transition: bottom 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: transparent;
  -webkit-transition: background-color 0s 0.15s linear,
    -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear,
    -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 0s 0.15s linear,
    -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: top 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: bottom 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    background-color 0s 0.075s linear,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::before {
  -webkit-transition: top 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r .hamburger-inner::after {
  -webkit-transition: bottom 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in,
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: transparent;
  -webkit-transition: background-color 0s 0.15s linear,
    -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear,
    -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 0s 0.15s linear,
    -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: top 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: top 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: bottom 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out,
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  -webkit-transition-duration: 0.075s;
  transition-duration: 0.075s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  -webkit-transition: bottom 0.075s 0.12s ease,
    -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease,
    -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition-delay: 0.12s;
  transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: bottom 0.075s ease,
    -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease,
    -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1),
    -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex .hamburger-inner::before,
.hamburger--vortex .hamburger-inner::after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.hamburger--vortex .hamburger-inner::before {
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
}

.hamburger--vortex .hamburger-inner::after {
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
  transform: rotate(765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex.is-active .hamburger-inner::before,
.hamburger--vortex.is-active .hamburger-inner::after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before,
.hamburger--vortex-r .hamburger-inner::after {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  -webkit-transition-property: top, opacity;
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, -webkit-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
  transform: rotate(-765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before,
.hamburger--vortex-r.is-active .hamburger-inner::after {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.o-about-box {
  background: #ffb443;
  padding: 30px 0;
  min-height: 450px;
  color: #0b1015;
  -webkit-box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.11);
  box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.11);
  border-top-left-radius: 0;
}
.o-about-box__content {
  position: relative;
  z-index: 1;
}
.o-about-box__title {
  font-family: "Trueno", Arial, Helvetica, sans-serif;
  font-size: 3rem;
  margin-bottom: 10px;
}
.o-about-box__title,
.o-about-box__subtitle {
  color: white;
}
.o-about-box__subtitle {
  font-family: "Trueno", Arial, Helvetica, sans-serif;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.1rem;
  font-weight: 300;
}
.o-about-box__bg {
  background-size: cover;
  border-bottom-right-radius: 50px;
}
.o-about-box__p {
  max-width: 500px;
  letter-spacing: 1px;
}
.o-about-box .o-intro-box__triangle {
  overflow: hidden;
}
.o-about-box .o-intro-box__triangle-shape {
  background: #ffb443;
}

.button,
.wpcf7-submit {
  display: inline-block;
  padding: 0.5rem 2.1rem 0.4rem 2rem;
  text-transform: uppercase;
  font-family: "Libre Franklin", sans-serif, helvetica;
  -webkit-transition: 0.5s ease all;
  transition: 0.5s ease all;
  letter-spacing: 5px !important;
  cursor: pointer;
  max-width: 38.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  text-decoration: none;
  font-size: 0.8rem !important;
  font-weight: 400;
  color: white;
  background: #0b1015;
  position: relative;
  height: 40px;
  border-top-left-radius: 15px;
  line-height: 1.7 !important;
  -webkit-box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.2);
  box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.2);
  border: none;
}
@media screen and (max-width: 450px) {
  .button,
  .wpcf7-submit {
    max-width: 100%;
    height: auto;
  }
  .button__icon,
  .wpcf7-submit__icon {
    height: 100% !important;
  }
}
.button--icon-yellow .button__icon,
.wpcf7-submit--icon-yellow .button__icon {
  /* background: ${colors.orange}; */
}
.button--icon-yellow .button__icon path,
.wpcf7-submit--icon-yellow .button__icon path {
  fill: black;
  /* fill: white; */
}
.button__border,
.wpcf7-submit__border {
  border-width: 1px;
  border-style: solid;
}
.button__icon,
.wpcf7-submit__icon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 0;
  border-radius: 0 0 20px 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  background: white;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.button__icon svg,
.wpcf7-submit__icon svg {
  width: 20px;
  height: 20px;
}
.button__icon svg path,
.wpcf7-submit__icon svg path {
  fill: #0b1015;
}

.cards {
  width: 80%;
  margin-left: 15%;
  margin-bottom: 70px;
}

.card {
  display: flex;
  margin-bottom: 0px;
  align-items: center;
}
.card__button {
  margin-left: 0;
  margin-top: 10px;
}
.card__title {
  margin-bottom: 10px;
  font-size: 3.5rem;
  color: #ffb443;
  font-family: "Trueno", "helvetica";
}
.card__text {
  width: 50%;
  color: #0b1015;
  z-index: -1;
  position: relative;
  display: flex;
  min-height: 370px;
  flex-direction: column;
  justify-content: center;
  padding: 50px 90px 50px 30px;
  letter-spacing: 0.25px;
  line-height: 1.7;
  border-bottom-right-radius: 80px;
}
.card__text:nth-last-child(2):first-child,
.card__text:nth-last-child(2):first-child ~ .card__text {
  width: 50% !important;
  padding: 50px 90px 50px 30px !important;
}
.card__image {
  width: 50%;
  height: 430px;
  position: relative;
  box-shadow: 0px 5px 15px 2px rgba(65, 65, 65, 0.1);
  background: white;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 40px;
}
.card:nth-child(even) {
  flex-direction: row-reverse;
}
.card:nth-child(even) .card__text {
  width: 40%;
  padding-right: 30px;
  padding-left: 70px;
}
.card:nth-child(even) .card__image {
  width: 60%;
}

/* .wpcf7-submit {
  font-family: "Trueno", Arial, Helvetica, sans-serif;
  border: none;
  border-bottom-right-radius: 15px;
  max-width: 300px !important;
  background: #ffb443;
  line-height: 1.6 !important;
  color: #0b1015;
  font-weight: 400;
  -webkit-box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.05) !important;
          box-shadow: 0 6px 12px 0px rgba(60, 60, 60, 0.05) !important; } */

.header-menu {
  max-width: 100%;
  position: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 40px 40px;
}
.header-menu * {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 300;
}
.header-menu__edges {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.header-menu__logo {
  max-height: 100%;
  max-width: 160px;
  line-height: 35px;
}
@media screen and (min-width: 400px) {
  .header-menu__logo {
    max-width: 225px;
  }
}
.header-menu__logo svg {
  height: 100%;
}
.header-menu.sticky .primary-menu__link {
  color: #0b1015;
  font-weight: 400;
  letter-spacing: 2.9px;
}
`
