import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import { Edges } from "components"

const Badges = props => {
  const { items } = props

  return (
    <Edges size="md">
      <Container>
        {items &&
          items.map((item, index) => {
            const { image } = item
            return (
              <Item key={index}>
                {image?.localFile?.childImageSharp?.fluid && (
                  <Img fluid={image?.localFile?.childImageSharp?.fluid} />
                )}
              </Item>
            )
          })}
      </Container>
    </Edges>
  )
}

export default Badges

const Container = styled.div`
  padding: 50px 0;

  @media (min-width: 850px) {
    padding: 100px 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`
const Item = styled.div`
  margin: 20px 0;
  width: calc(50% - 20px);

  @media (min-width: 850px) {
    width: calc((100% / 4) - 80px);
  }
`
